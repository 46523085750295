import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ApiResult} from '../../model/ApiResult';
import {catchError, tap} from 'rxjs/operators';
import {LocalStorageService} from '../../data/local-storage.service';
import {BaseService} from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService extends BaseService{

  private getTenantProfileUrl = this.baseApiUrl + 'account/tenant/api/profile';
  private getTenantListUrl    = this.baseApiUrl + 'account/tenant/api/user/list';
  private switchTenantUrl     = this.baseApiUrl + 'account/tenant/api/switch';
  private updateTenantNameUrl = this.baseApiUrl + 'account/tenant/api/update';
  private joinTenantUrl       = this.baseApiUrl + 'account/tenant/api/join';

  constructor(public http: HttpClient,
              public localStorageService: LocalStorageService) {
    super();
  }

  getTenantProfile(): Observable<ApiResult> {

      const tokenStr  = this.localStorageService.getToken();
      const newHeaders = {
        Token: tokenStr
      };
      const newOptions = {
        headers: new HttpHeaders(newHeaders)
      };
      // @ts-ignore
      return this.http.post(this.getTenantProfileUrl, null, newOptions).pipe(
        tap(_ => this.log(`login user name =`)),
        catchError(this.handleError<ApiResult>('login'))
      );
    }

  // tslint:disable-next-line:typedef
    private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

  // tslint:disable-next-line:typedef
    private log(message: string) {
      // this.messageService.add(`UserService: ${message}`);
    }


  getTenantList(): Observable<ApiResult> {

    const tokenStr  = this.localStorageService.getToken();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    // @ts-ignore
    return this.http.post(this.getTenantListUrl, null, newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }

  switchTenant(selectedTenant: string): Observable<ApiResult> {

    const tokenStr  = this.localStorageService.getToken();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    const  param = '?selectedTenant=' + selectedTenant;
    // @ts-ignore
    return this.http.post(this.switchTenantUrl + param, null, newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }


  updateTenantName(tenantName: string): Observable<ApiResult> {

    const tokenStr  = this.localStorageService.getToken();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    const  param = '?tenantName=' + tenantName;
    // @ts-ignore
    return this.http.post(this.updateTenantNameUrl + param, null, newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }

  joinTenant(tenantUnionId: string): Observable<ApiResult> {

    const tokenStr  = this.localStorageService.getToken();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    const  param = '?id=' + tenantUnionId;
    // @ts-ignore
    return this.http.post(this.joinTenantUrl + param, null, newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }
}
