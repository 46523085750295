import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NzCarouselComponent} from 'ng-zorro-antd/carousel';

@Component({
  selector: 'app-main-part',
  templateUrl: './main-part.component.html',
  styleUrls: ['./main-part.component.css']
})
export class MainPartComponent implements OnInit, AfterViewInit {

  isCollapsed = false;
  baseImageCommonUrl = environment.baseImageCommonUrl;
  // array = [['  ～我变强了？？', this.baseImageCommonUrl + 'tab001.png'],
  //   ['  ～那是什么存在？？' , this.baseImageCommonUrl + 'tab002.png'],
  //     ['          ～我会飞！！？？' , this.baseImageCommonUrl + 'tab003.jpeg']];

  @ViewChild('carousel') carousel!: NzCarouselComponent;
  array = [
    ['  ～我会飞！！？？' , this.baseImageCommonUrl + 'all.png'],
     ['  ～我会飞！！？？' , this.baseImageCommonUrl + 'lowcode_1.png'],
    ['  ～我会飞！！？？' , this.baseImageCommonUrl + 'ai_1.png'],
    ['  ～我会飞！！？？' , this.baseImageCommonUrl + 'rpa_1.png'],
    ['  ～我会飞！！？？' , this.baseImageCommonUrl + 'data.png'],
    ['  ～我会飞！！？？' , this.baseImageCommonUrl + 'mkt_1.png'],
  ];

  sections = [
    { imageUrl: 'assets/images/lowcode_1.png' },
  ];

  // @HostListener('window:wheel', ['$event'])
  // onWheel(event: WheelEvent): void {
  //   if (event.deltaY > 0) {
  //     window.scrollBy({
  //       top: window.innerHeight,
  //       behavior: 'smooth'
  //     });
  //   } else {
  //     window.scrollBy({
  //       top: -window.innerHeight,
  //       behavior: 'smooth'
  //     });
  //   }
  // }
  currentIndex = 0;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Initialize currentIndex
    this.currentIndex = this.carousel.activeIndex || 0;

    // Listen to changes in carousel
    this.carousel.nzBeforeChange.subscribe((event: { to: number }) => {
      this.currentIndex = event.to;
    });
  }

  prevSlide(): void {
    this.carousel.pre(); // 处理点击左侧按钮时调用prev方法
  }

  nextSlide(): void {
    this.carousel.next(); // 处理点击右侧按钮时调用next方法
  }

  isPrevVisible(): boolean {
    return this.currentIndex > 0;
  }

  isNextVisible(): boolean {
    return this.currentIndex < this.array.length - 1;
  }

}
