<div class="logo-group">
    <div>
      <a href="/ucenter">
        <img
        nz-image
        class="logo-img"
        [src]="userLogo"
        alt=""/>
      </a>
    </div>
    <div class="logo-title-area">
<!--        <span class="logo-title">无限生鸡</span>-->
      <img
        nz-image
        class="logo-title"
        [src]="logoTitle"
        alt=""/>
    </div>
    <div class="logo-tab-area">
       <nz-tag [nzColor]="'black'" class="logo-tab">生产力「提升」平台</nz-tag>
    </div>
</div>
