import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonModule, registerLocaleData } from '@angular/common';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import zh from '@angular/common/locales/zh';
import en from '@angular/common/locales/en';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import { IconsProviderModule } from './icons-provider.module';
// import { NzLayoutModule } from 'ng-zorro-antd/layout';
// import { NzMenuModule } from 'ng-zorro-antd/menu';
// import {LoginComponent} from './pages/login/login.component';
// import {LoginModule} from './pages/login/login.module';
// import {NzFormItemComponent, NzFormModule} from 'ng-zorro-antd/form';
// import {NzInputModule} from 'ng-zorro-antd/input';
// import {NzButtonModule} from 'ng-zorro-antd/button';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';
import {RegisterModule} from './pages/register/register.module';
import {UcenterModule} from './pages/ucenter/ucenter.module';
import {TermsAndConsModule} from './pages/terms-and-cons/terms-and-cons.module';
import {SharedModule} from './shared/shared.module';
import {LogoutComponent} from './@core/listener/logout/logout.component';
import {RedirectComponent} from './pages/redirect/redirect.component';
import {RefreshTokenComponent} from './@core/listener/refresh-token/refresh-token.component';
import {HeaderComponent} from './pages/settings/header/header.component';


registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LogoutComponent,
    RefreshTokenComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    CommonModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    ReactiveFormsModule,
    DemoNgZorroAntdModule,
    ScrollingModule,
    DragDropModule,
    AppRoutingModule,
    UcenterModule,
    RegisterModule,
    TermsAndConsModule,
    SharedModule
  ],
  providers: [{ provide: NZ_I18N, useValue: zh_CN }],
  bootstrap: [AppComponent]
})
export class AppModule { }
