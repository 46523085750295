<app-header-bar></app-header-bar>
<div class="container">
  <nz-tabset nzType="card" class="card-style">
    <nz-tab  nzTitle= "帐号注册">
        <form nz-form [formGroup]="validateForm" (ngSubmit)="registerSubmitFormOne()">
          <nz-form-item>
            <nz-form-control   [nzErrorTip]="nameErrorTpl" >
              <nz-input-group nzPrefixIcon="user">
                 <input nz-input id="nickname" formControlName="nickname"  placeholder="用户名" nzPrefixIcon="user"/>
              </nz-input-group>
              <ng-template #nameErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">请输入你的用户名</ng-container>
                <ng-container *ngIf="control.hasError('nameInvalid')">用户名已占用</ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control   [nzErrorTip]="passwordErrorTpl" >
              <nz-input-group nzPrefixIcon="lock">
                <input
                  nz-input
                  type="password"
                  id="password"
                  formControlName="password"
                  (ngModelChange)="updateConfirmValidator()"
                  placeholder="密码"
                />
              </nz-input-group>
              <ng-template #passwordErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">请输入密码</ng-container>
                <ng-container *ngIf="control.hasError('minlength')">密码最少6个字符或数字</ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control   [nzErrorTip]="errorTpl">
              <nz-input-group nzPrefixIcon="lock">
                 <input nz-input type="password" formControlName="checkPassword" id="checkPassword" placeholder="确认密码" />
              </nz-input-group>
              <ng-template #errorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">请输入确认密码</ng-container>
                <ng-container *ngIf="control.hasError('confirm')">两次密码不一致</ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control [nzErrorTip]="moibleErrorTpl"
              nzPrefixIcon="mobile"
            >
              <nz-input-group nzPrefixIcon="mobile">
                <input formControlName="mobile" id="'mobile'" nz-input placeholder="手机号码"  />
              </nz-input-group>
              <ng-template #moibleErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">请输入手机号码</ng-container>
                <ng-container *ngIf="control.hasError('mobileInvalid')">手机码号已注册</ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control
              [nzSm]="23"
              [nzXs]="23" [nzErrorTip]="validateCodeErrorTpl">
              <div nz-row [nzGutter]="8">
                <div nz-col [nzSpan]="16"  >
                  <nz-input-group nzPrefixIcon="key">
                    <input nz-input formControlName="validateCode" id="validateCode"  placeholder="验证码"  />
                  </nz-input-group>
                </div>
                <div nz-col [nzSpan]="8">
                  <button nz-button class="custom-rounded-input"
                          [disabled]="sendValidateCodeBtnDisableFlag"
                          (click)="sendVerifiedCode($event)"
                          id="sendValidateCodeBtn" >{{sendValidateCodeBtnMsg}}</button>
                </div>
              </div>
              <ng-template #validateCodeErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">请输入你的验证</ng-container>
                <ng-container *ngIf="control.hasError('codeExpired')">验证码过期</ng-container>
                <ng-container *ngIf="control.hasError('codeIncorrect')">验证码不正确</ng-container>
                <ng-container *ngIf="control.hasError('serviceError')">服务异常，稍后再试</ng-container>
              </ng-template>
              <nz-alert style="min-width:100%;"
                *ngIf="isSendCodeSuccess"
                nzType="success"
                nzMessage="短信验证码已成功发送，请耐心等待。"
                nzShowIcon
              ></nz-alert>
            </nz-form-control>
          </nz-form-item>
<!--          <nz-form-item>-->
<!--            <nz-form-control   nzErrorTip="邮箱地址无效!">-->
<!--              <nz-input-group nzPrefixIcon="mail">-->
<!--                 <input nz-input formControlName="email" id="email" placeholder="邮箱地址" nzPrefixIcon="email"/>-->
<!--              </nz-input-group>-->
<!--            </nz-form-control>-->
<!--          </nz-form-item>-->
          <nz-form-item nz-row class="register-area">
            <nz-form-control  nzErrorTip="请确认同意服务条款!">
              <label nz-checkbox formControlName="agreement">
                   <span>我已阅读并同意</span>
              </label>
              <a href="/termsandcons" target="_blank">服务条款、隐私政策</a>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-row class="register-area">
            <nz-form-control  >
              <button nz-button nzType="primary" class="register-button button-class" [nzLoading]="isLoadingOne" >注册</button>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item nz-row class="register-area">
              <nz-form-control  >
                <a href="/login">登陆!</a>
              </nz-form-control>
          </nz-form-item>
        </form>
    </nz-tab>
    <nz-tab  nzTitle= "手机号注册">
      <form nz-form [formGroup]="validateForm2" (ngSubmit)="registerSubmitFormTwo()">
        <nz-form-item>
            <nz-form-control
              [nzErrorTip]="moible2ErrorTpl"
              nzPrefixIcon="mobile"
            >
              <nz-input-group nzPrefixIcon="mobile">
                 <input formControlName="mobile2" id="'mobile2'" nz-input placeholder="手机号码"  />
              </nz-input-group>
              <ng-template #moible2ErrorTpl let-control>
                <ng-container *ngIf="control.hasError('required')">请输入手机号码</ng-container>
                <ng-container *ngIf="control.hasError('mobile2Invalid')">手机码号已注册</ng-container>
              </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control
            [nzSm]="23"
            [nzXs]="23"
            [nzErrorTip]="validateCode2ErrorTpl" >
            <div nz-row [nzGutter]="8">
              <div nz-col [nzSpan]="16"  >
                <nz-input-group nzPrefixIcon="key">
                  <input nz-input formControlName="validateCode2" id="validateCode2"  placeholder="验证码"  />
                </nz-input-group>
              </div>
              <div nz-col [nzSpan]="8">
                <button nz-button  class="custom-rounded-input"
                        [disabled]="sendValidateCodeBtnDisableFlag2"
                        (click)="sendVerifiedCode2($event)"
                        id="sendValidateCodeBtn2" >{{sendValidateCodeBtnMsg2}}</button>
              </div>
            </div>
            <ng-template #validateCode2ErrorTpl let-control>
              <ng-container *ngIf="control.hasError('required')">请输入你的验证</ng-container>
              <ng-container *ngIf="control.hasError('code2Expired')">验证码过期</ng-container>
              <ng-container *ngIf="control.hasError('code2Incorrect')">验证码不正确</ng-container>
              <ng-container *ngIf="control.hasError('service2Error')">服务异常，稍后再试</ng-container>
            </ng-template>
            <nz-alert style="min-width:100%;"
                      *ngIf="isSendCodeSuccess2"
                      nzType="success"
                      nzMessage="短信验证码已成功发送，请耐心等待。"
                      nzShowIcon
            ></nz-alert>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row class="register-area">
          <nz-form-control  nzErrorTip="请确认同意服务条款!" >
            <label nz-checkbox formControlName="agreement2">
              <span>我已阅读并同意</span>
            </label>
            <a href="/termsandcons" target="_blank">服务条款、隐私政策</a>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row class="register-area">
          <nz-form-control  >
            <button nz-button nzType="primary" class="register-button button-class" [nzLoading]="isLoadingTwo" >注册</button>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row class="register-area">
          <nz-form-control  >
            <a href="/login">登陆!</a>
          </nz-form-control>
        </nz-form-item>
      </form>
    </nz-tab>
  </nz-tabset>
</div>
<app-footer-bar></app-footer-bar>
