import { Component, OnInit } from '@angular/core';
import {Constants} from '../../@core/constant/Constants';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public goMainPage(): void {
    window.location.href = '/';
  }
}
