import {AfterViewInit, Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzFormTooltipIcon } from 'ng-zorro-antd/form';
import {Router} from '@angular/router';
import {catchError, take} from 'rxjs/operators';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Constants} from '../../@core/constant/Constants';
import {AuthService} from '../../@core/services/http/auth/auth.service';
import {LocalStorageService} from '../../@core/services/data/local-storage.service';
import {JumpService} from '../../@core/services/http/jump/jump.service';
import {MarketService} from '../../@core/services/http/market/market.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit, AfterViewInit {

  validateForm!: FormGroup;

  validateForm2!: FormGroup;

  loginByUserNameAndPasswordFlag = true;

  sendValidateCodeBtnMsg = '发送验证码';

  sendValidateCodeBtnDisableFlag = false;

  countdown = 60;


  loginByUserNameAndPasswordFlag2 = true;

  sendValidateCodeBtnMsg2 = '发送验证码';

  sendValidateCodeBtnDisableFlag2 = false;

  countdown2 = 60;


  isSendCodeSuccess = false;

  isSendCodeSuccess2 = false;

  isLoadingOne = false;

  isLoadingTwo = false;

  captchaTooltipIcon: NzFormTooltipIcon = {
    type: 'info-circle',
    theme: 'twotone'
  };


  constructor(
          private message: NzMessageService,
          private fb: FormBuilder,
          private authService: AuthService,
          private jumpService: JumpService,
          private marketService: MarketService,
          private localStorageService: LocalStorageService,
          public router: Router) {}



  ngOnInit(): void {

    this.authService.checkLoginForLoginAndReg();

    this.validateForm = this.fb.group({
      nickname: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      mobile: [null, [Validators.required]],
      validateCode: [null, [Validators.required]],
      agreement: [true , [Validators.requiredTrue]]
    });


    this.validateForm2 = this.fb.group({
      mobile2: [null, [Validators.required]],
      validateCode2: [null, [Validators.required]],
      agreement2: [true , [Validators.requiredTrue]]
    });

  }

  ngAfterViewInit(): void {
    console.log('call preLoadWorkbrenchPage');
    this.localStorageService.preLoadWorkbrenchPage();
  }


  sendVerifiedCode(e: MouseEvent): void {


    e.preventDefault();
    // Check mobile field , must filled
    if (!this.validateForm.get('mobile').valid) {
      this.validateForm.get('mobile')?.markAsDirty();
      this.validateForm.get('mobile')?.updateValueAndValidity({onlySelf: true});
      return;
    }
    // Check agreement field , must checked
    if (!this.validateForm.get('agreement').valid) {
      this.validateForm.get('agreement')?.markAsDirty();
      this.validateForm.get('agreement')?.updateValueAndValidity({onlySelf: true});
      return;
    }

    this.sendValidateCodeBtnDisableFlag = true;
    const params = this.validateForm.value;
    this.authService.sendVerifiedCodeForRegister(params.mobile).subscribe(res => {
      if (res.success) {
        this.updateSendVerButton();
       // this.messageSevice.create('success', `短信验证码已发送，可能会有延后，请耐心等待`);
        this.isSendCodeSuccess = true;
        setTimeout(() => {
          this.isSendCodeSuccess = false;
        }, 7000);
      } else {
        this.sendValidateCodeBtnDisableFlag = false;
        const control = this.validateForm.get('validateCode');
        control.setErrors({ captchaFail: true });
      }
    });
  }

  updateSendVerButton(): void {
    // @ts-ignore
    this.sendValidateCodeBtnDisableFlag = true;
    const start = setInterval(() => {
      if (this.countdown >= 0) {
        this.sendValidateCodeBtnMsg = '已发送(' + this.countdown-- + ')';         // 动态的进行倒计时
      } else {
        clearInterval(start);                         // 如果超时则重新发送
        this.sendValidateCodeBtnMsg = '再一次发送';
        this.sendValidateCodeBtnDisableFlag = false;  // 按钮再次变成可点击状态
        this.countdown = 60;
      }
    }, 1000);
  }


  registerSubmitFormOne(): void {

    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.isLoadingOne = true;
    const params = this.validateForm.value;
    this.authService.register({name: params.nickname, password: params.password, mobile: params.mobile, code: params.validateCode})
      .pipe(
        take(1),
        catchError(err => {
          return err;
        })).subscribe(res => {
          if ( res.success) {
            this.isLoadingOne = false;
            this.message.success('注册成功');
            this.localStorageService.setUserId(res.result.userUnionId);
            this.localStorageService.setToken(res.result.token);
            this.marketService.installDefaultApp();
            this.localStorageService.loginNotificationForContact(res.result.token);
            this.localStorageService.loginNotificationForWorkbrench(res.result.token);
            this.jumpService.jumpToWork2000WithToken();
          }
          else
          {
            this.isLoadingOne = false;
            if (res.code === '1')
            {
              const control = this.validateForm.get('mobile');
              control.setErrors({ mobileInvalid: true });
            }
            else if (res.code === '2')
            {
              const control = this.validateForm.get('validateCode');
              control.setErrors({ codeIncorrect: true });
            }
            else if (res.code === '3')
            {
              const control = this.validateForm.get('validateCode');
              control.setErrors({ codeExpired: true });
            }
            else if (res.code === '4')
            {
              const control = this.validateForm.get('validateCode');
              control.setErrors({ serviceError: true });
            }
            else if (res.code === '5')
            {
              const control = this.validateForm.get('nickname');
              control.setErrors({ nameInvalid: true });
            }
          }
          return;
    });
  }



  /*
  *  register by CAPTCHA
   */




  sendVerifiedCode2(e: MouseEvent): void {


    e.preventDefault();
    // Check mobile field , must filled
    if (!this.validateForm2.get('mobile2').valid) {
      this.validateForm2.get('mobile2')?.markAsDirty();
      this.validateForm2.get('mobile2')?.updateValueAndValidity({onlySelf: true});
      return;
    }
    // Check agreement field , must checked
    if (!this.validateForm2.get('agreement2').valid) {
      this.validateForm2.get('agreement2')?.markAsDirty();
      this.validateForm2.get('agreement2')?.updateValueAndValidity({onlySelf: true});
      return;
    }

    this.sendValidateCodeBtnDisableFlag2 = true;
    const params = this.validateForm2.value;
    this.authService.sendVerifiedCodeForRegister(params.mobile2).subscribe(res => {
      if (res.success) {
        this.updateSendVerButton2();
        // this.messageSevice.create('success', `短信验证码已发送，可能会有延后，请耐心等待`);
        this.isSendCodeSuccess2 = true;
        setTimeout(() => {
          this.isSendCodeSuccess2 = false;
        }, 7000);
      } else {
        this.sendValidateCodeBtnDisableFlag2 = false;
        const control = this.validateForm2.get('validateCode2');
        control.setErrors({ captchaFail2: true });
      }
    });

  }

  updateSendVerButton2(): void {
    // @ts-ignore
    this.sendValidateCodeBtnDisableFlag2 = true;
    const start = setInterval(() => {
      if (this.countdown2 >= 0) {
        this.sendValidateCodeBtnMsg2 = '已发送(' + this.countdown2-- + ')';         // 动态的进行倒计时
      } else {
        clearInterval(start);                         // 如果超时则重新发送
        this.sendValidateCodeBtnMsg2 = '再一次发送';
        this.sendValidateCodeBtnDisableFlag2 = false;  // 按钮再次变成可点击状态
        this.countdown2 = 60;
      }
    }, 1000);
  }

  registerSubmitFormTwo(): void {

    if (this.validateForm2.valid) {
      console.log('submit', this.validateForm2.value);
    } else {
      Object.values(this.validateForm2.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
      return;
    }

    this.isLoadingTwo = true;
    const params = this.validateForm2.value;
    this.authService.register({mobile: params.mobile2, code: params.validateCode2})
      .pipe(
        take(1),
        catchError(err => {
          return err;
        })).subscribe(res => {
      if ( res.success) {
        this.isLoadingTwo = false;
        this.message.success('注册成功');
        this.localStorageService.setUserId(res.result.userUnionId);
        this.localStorageService.setToken(res.result.token);
        this.marketService.installDefaultApp();
        this.localStorageService.loginNotificationForContact(res.result.token);
        this.localStorageService.loginNotificationForWorkbrench(res.result.token);
        this.jumpService.jumpToWork2000WithToken();
      }
      else
      {
        this.isLoadingTwo = false;
        if (res.code === '1')
        {
          const control = this.validateForm2.get('mobile2');
          control.setErrors({ mobile2Invalid: true });
        }
        else if (res.code === '2')
        {
          const control = this.validateForm2.get('validateCode2');
          control.setErrors({ code2Incorrect: true });
        }
        else if (res.code === '3')
        {
          const control = this.validateForm2.get('validateCode2');
          control.setErrors({ code2Expired: true });
        }
        else if (res.code === '4')
        {
          const control = this.validateForm2.get('validateCode2');
          control.setErrors({ service2Error: true });
        }
        else if (res.code === '5')
        {
          const control = this.validateForm2.get('validateCode2');
          control.setErrors({ service2Error: true });
        }
      }
      return;
    });
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }



/*
* common method
 */


  jumpToMain(): void  {
    setTimeout(() => {
      this.jumpService.jumpToWork();
    }, 2000);
  }
}
