import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable } from 'rxjs';
import { filter, map, } from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

export interface ActionResult<T> {
  code: string;
  message: string;
  result: T;
}
export interface MyHttpConfig {
  needIntercept?: boolean;
  needSuccessInfo?: boolean;
  showLoading?: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  baseApiUrl = environment.baseApiUrl;

  token = '';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(public http: HttpClient, public message: NzMessageService) { }
  post<T>(path: string, param?: NzSafeAny, config?: MyHttpConfig, requiredResult?: boolean): Observable<NzSafeAny> {
    config = config || { needSuccessInfo: false };
    requiredResult = requiredResult || false;
    this.setHeader();
    return this.http.post<ActionResult<T>>(this.baseApiUrl + path, param, this.httpOptions).pipe(
      filter((item) => {
        return this.handleFilter(item, !!(config?.needSuccessInfo));
      }),
      map((item) => {
        if (item.code !== '00000') {
          throw new Error(item.message);
        }
        return item;
      }),
      map(item => {
        if (requiredResult)
        {
          return item;
        }
        else
        {
          return item?.result;
        }
      })
    );
  }

  setHeader(): void {
    this.token = window.localStorage.getItem('USER-TOKEN') || '';
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        token: this.token
      })
    };
  }

  downZip(path: string, param?: NzSafeAny): Observable<NzSafeAny> {
    return this.http.post(this.baseApiUrl + path, param, {
      responseType: 'blob',
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/vnd.ms-excel;charset=utf-8',
          token: this.token
        }
      )
    });
  }

  handleFilter(item: ActionResult<NzSafeAny>, needSuccessInfo: boolean): boolean {
    if (item.code !== '00000') {
      this.message.error(item.message);
    } else if (needSuccessInfo) {
      this.message.success('操作成功');
    }
    return true;
  }
}
