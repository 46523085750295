import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Constants} from '../../constant/Constants';


const ls = localStorage;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  baseApiUrl = environment.baseApiUrl;
  baseMainWebUrl = environment.baseMainWebUrl;
  baseWorkWebUrl = environment.baseWorkWebUrl;
  baseContactWebUrl = environment.baseContactWebUrl;

  constructor() { }

  public getTenantUnionId(): any {
    return ls.getItem(Constants.KEY_LS_TENANT_UNION_ID);
  }

  public setTenantUnionId(unionId: string): void {
    ls.setItem(Constants.KEY_LS_TENANT_UNION_ID, unionId);
  }

  public getUserName(): any {
    return ls.getItem(Constants.KEY_LS_USER_SYS_ID);
  }

  public setUserName(userName: string): void {
    ls.setItem(Constants.KEY_LS_USER_SYS_ID, userName);
  }

  public getUserId(): any {
    return ls.getItem(Constants.KEY_LS_USER_SYS_ID);
  }

  public setUserId(userId: string): void {
    ls.setItem(Constants.KEY_LS_USER_SYS_ID, userId);
  }


  // public setToken(token: string): void {
  //   ls.setItem(Constants.KEY_LS_TOKEN, token);
  // }

  public getTokenWithLoginRedirect(): any {

    const token = ls.getItem(Constants.KEY_LS_TOKEN);
    if (token === '' || token === undefined) {
      window.location.assign(Constants.URL_MOD_LOGIN);
    }
    return token;
  }

  public getToken(): any {

    const token = ls.getItem(Constants.KEY_LS_TOKEN);
    return token;

  }


  public setToken(token: string): void {

    ls.setItem(Constants.KEY_LS_TOKEN, token);
  }


  public clearUserInfo(): any {
     ls.removeItem(Constants.KEY_LS_USER_SYS_ID);
     ls.removeItem(Constants.KEY_LS_USER_NAME);
     ls.removeItem(Constants.KEY_LS_TOKEN);
  }

// 触发对方的监听器后，要等一定的时间间隔。否则影响通信
  public loginNotificationForContact(token: string): void {
    console.log('loginNotificationForContact');
    const iframe2 = document.createElement('iframe');
    iframe2.src = this.baseContactWebUrl + 'loginrecevier';
    document.body.appendChild(iframe2);
    setTimeout(() => {
       iframe2.contentWindow.postMessage(token, this.baseContactWebUrl + 'loginrecevier');
    }, 100);
    setTimeout(() =>  {
      iframe2.remove();
    }, 3000);
    // setTimeout(() => {
    //   iframe2.contentWindow.postMessage(token, this.baseContactWebUrl + 'loginrecevier');
    // }, 500);
    // setTimeout(() =>  {
    //   iframe2.remove();
    // }, 1500);
  }

  public loginNotificationForWorkbrench(token: string): void {
    console.log('loginNotificationForWorkbrench');
    const iframe3 = document.createElement('iframe');
    iframe3.src = this.baseWorkWebUrl + 'workbrenchloginrecevier';
    document.body.appendChild(iframe3);
    // setTimeout(() => {
    //      iframe3.contentWindow.postMessage(token, this.baseWorkWebUrl + 'workbrenchloginrecevier');
    // }, 5200);
    // setTimeout(() => {
    //   iframe3.remove();
    // }, 10000);
    setTimeout(() => {
      iframe3.contentWindow.postMessage(token, this.baseWorkWebUrl + 'workbrenchloginrecevier');
    }, 2000);
    setTimeout(() => {
      iframe3.remove();
    }, 3000);
  }


  public logoutNotificationForContact(): void {
    console.log('logoutNotificationForContact');
    const iframe2 = document.createElement('iframe');
    iframe2.src = this.baseContactWebUrl + 'logoutrecevier';
    document.body.appendChild(iframe2);
    setTimeout(() =>  {
      iframe2.contentWindow.postMessage('', this.baseContactWebUrl + 'logoutrecevier');
    }, 500);
    setTimeout(() =>  {
      iframe2.remove();
    }, 2000);
  }

  public logoutNotificationForWorkbrench(): void {
    console.log('logoutNotificationForWorkbrench');
    const iframe3 = document.createElement('iframe');
    iframe3.src = this.baseWorkWebUrl + 'workbrenchlogoutrecevier';
    document.body.appendChild(iframe3);
    setTimeout(() =>  {
      iframe3.contentWindow.postMessage('', this.baseWorkWebUrl + 'workbrenchlogoutrecevier');
    }, 3000);
    setTimeout(() =>  {
      iframe3.remove();
    }, 5000);
  }



  public preLoadWorkbrenchPage(): void {
    // alert('preLoadWorkbrenchPage');
    console.log('preLoadWorkbrenchPage');
    const iframe = document.createElement('iframe');
    iframe.src = this.baseWorkWebUrl + Constants.URL_MOD_FORM + '?offline=1';
    document.body.appendChild(iframe);
  }

}
