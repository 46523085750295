import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiResult } from '../../model/ApiResult';
import {LocalStorageService} from '../../data/local-storage.service';
import {JumpService} from '../jump/jump.service';
import {BaseService} from '../../base.service';


const headersParam = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' });
headersParam.append('Access-Control-Allow-Origin',  '*' );
headersParam.append('transfer-encoding',  'chunked');
headersParam.append('X-Content-Type-Options',  'nosniff');
headersParam.append('X-XSS-Protection',  '1;mode=block');

const httpOptions = {
  headers: headersParam
};

// application/x-www-form-urlencoded;charset=utf-8
@Injectable({
  providedIn: 'root'
})

export class AuthService extends BaseService{

  messages: string[] = [];
  public isLoggedIn = false;


  // need to check token
  private getUserProfileByUnionIdUrl = this.baseApiUrl + 'account/user/api/get/profile';
  private getMyProfileByUnionIdUrl   = this.baseApiUrl + 'account/user/api/get/myprofile';
  private changePwUrl                = this.baseApiUrl + 'account/user/api/pwd/change';
  private sendVerCodeForLoginUrl     = this.baseApiUrl + 'aam/user/api/sendvercode';
  private sendVerCodeForChangePwUrl  = this.baseApiUrl + 'account/user/api/security/sendvercode';
  private verifyCodeForChangePwUrl   = this.baseApiUrl + 'account/user/api/security/verifiycode';
  private updateProfileNameUrl       = this.baseApiUrl + 'account/user/api/update/name';
  private updateProfileImgUrlUrl        = this.baseApiUrl + 'account/user/api/update/imgurl';


  // without token checking
  private loginUrl                   = this.baseApiUrl + 'aam/loginaction';
  private registerUrl                = this.baseApiUrl + 'account/user/security/api/registeraction';
  private sendVerCodeForRegisterUrl  = this.baseApiUrl + 'account/user/security/api/reg/sendvercode';
  private getUserProfileByTokenUrl   = this.baseApiUrl + 'account/user/security/api/profilebytoken';



  constructor(public http: HttpClient,
              private jumpService: JumpService,
              public localStorageService: LocalStorageService) {
       super();
  }


  login(usernameParam: string, passwordParam: string): Observable<ApiResult> {

    const newHeaders = {
      'LOGIN-METHOD': 'USERNAME-PW'
    };

    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };

    const param = '?username=' + usernameParam + '&password=' + passwordParam;

    // @ts-ignore
    return this.http.post(this.loginUrl + param, '', newOptions).pipe(
      tap(_ => this.log(`login user name =${usernameParam}`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }

  sendVerifiedCodeForLogin(mobileParam: string): Observable<any> {
    const param = 'mobile=' + mobileParam;
    return this.http.post(this.sendVerCodeForLoginUrl + '?' + param, '');
  }

  sendVerifiedCodeForChangePW(mobileParam: string): Observable<any> {
    const tokenStr  = this.localStorageService.getToken();
    const userUnionId  = this.localStorageService.getUserId();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    const param = 'mobile=' + mobileParam;
    return this.http.post(this.sendVerCodeForChangePwUrl + '?' + param, '', newOptions);
  }



  logout(): void {
    this.isLoggedIn = false;
  }


  loginByMobile(mobileParam: string, codeParam: string): Observable<ApiResult> {

    const newHeaders = {
      'LOGIN-METHOD': 'MOBILE-CODE'
    };

    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    // @ts-ignore
    return this.http.post(this.loginUrl + '?username=' + mobileParam + '&password=' + codeParam, '', newOptions).pipe(
      tap(_ => this.log(`login by mobile`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }

  // tslint:disable-next-line:typedef
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // tslint:disable-next-line:typedef
  private log(message: string) {
    // this.messageService.add(`UserService: ${message}`);
  }

  /*
  *  For user register
   */
  sendVerifiedCodeForRegister(mobileParam: string): Observable<any> {
    const param = 'mobile=' + mobileParam;
    return this.http.post(this.sendVerCodeForRegisterUrl + '?' + param, '');
  }


  register(params: any): Observable<any> {
    return this.http.post(this.registerUrl, params);
  }

  getUserProfileByToken(params: any): Observable<ApiResult> {

      const newHeaders = {
        Token: params
      };
      const newOptions = {
        headers: new HttpHeaders(newHeaders)
      };

      // @ts-ignore
      return this.http.post(this.getUserProfileByTokenUrl, '', newOptions).pipe(
        tap(_ => this.log(`login user name =`)),
        catchError(this.handleError<ApiResult>('login'))
      );
  }

  checkLoginForLoginAndReg(): void {

    const tokenStr  = this.localStorageService.getToken();
    if (tokenStr !== null ) {
      this.getUserProfileByToken(tokenStr)
        .subscribe(data => {
          if (data.success) {
            this.jumpService.jumpToWork2000WithToken();
            return;
          }
        });
    } else {
      // this.jumpToWork();
      return;
    }
  }

  checkLogin(): boolean {
    const tokenStr  = this.localStorageService.getToken();
    if (tokenStr === null || tokenStr === '' || typeof  tokenStr  === 'undefined') {
      this.jumpService.jumpToLogin();
      return;
    }
    this.getUserProfileByToken(tokenStr)
      .subscribe(res => {
        if (res.success) {
          return true;
        }
        else
        {
          this.jumpService.jumpToLogin();
        }
      });
  }




  getMyProfile(): Observable<ApiResult> {

    const tokenStr  = this.localStorageService.getToken();
    const userUnionId  = this.localStorageService.getUserId();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    const param = 'USER-UNION-ID=' + userUnionId;
    // @ts-ignore
    return this.http.post(this.getMyProfileByUnionIdUrl, param, newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }


  /*
  *  以下方法都是需要进行安全性检测，包括token是否正确，token是否过期。
  */

  getUserProfile(): Observable<ApiResult> {

    const tokenStr  = this.localStorageService.getToken();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    // @ts-ignore
    return this.http.post(this.getUserProfileByUnionIdUrl, '', newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }

  changePW(param: any): Observable<ApiResult>  {
    const tokenStr = this.localStorageService.getToken();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    // @ts-ignore
    return this.http.post(this.changePwUrl, param, newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }

  verifyCaptcha(param: any): Observable<ApiResult> {
      const tokenStr  = this.localStorageService.getToken();
      const newHeaders = {
        Token: tokenStr
      };
      const newOptions = {
        headers: new HttpHeaders(newHeaders)
      };
      // @ts-ignore
      return this.http.post(this.verifyCodeForChangePwUrl, param, newOptions).pipe(
        tap(_ => this.log(`login user name =`)),
        catchError(this.handleError<ApiResult>('login'))
      );
   }


  updateProfileName(userName: string): Observable<ApiResult> {

    const tokenStr  = this.localStorageService.getToken();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    const  param = '?userName=' + userName;
    // @ts-ignore
    return this.http.post(this.updateProfileNameUrl + param, null, newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }

  updateProfileImgUrl(imgUrl: string): Observable<ApiResult> {

    const tokenStr  = this.localStorageService.getToken();
    const newHeaders = {
      Token: tokenStr
    };
    const newOptions = {
      headers: new HttpHeaders(newHeaders)
    };
    const  param = '?imgUrl=' + imgUrl;
    // @ts-ignore
    return this.http.post(this.updateProfileImgUrlUrl + param, null, newOptions).pipe(
      tap(_ => this.log(`login user name =`)),
      catchError(this.handleError<ApiResult>('login'))
    );
  }

}

// // export class AuthService {
// //
// //   isLoggedIn = false;
// //
// //   // store the URL so we can redirect after logging in
// //   redirectUrl: string;
// //
// //   login(): Observable<boolean> {
// //     return of(true).pipe(
// //       delay(1000),
// //       tap(val => this.isLoggedIn = true)
// //     );
// //   }
// //

// // }



// export class AuthService {
//


//
//   // getUsers(): Observable<User[]> {
//   //   return this.http.get<User[]>(this.usersUrl)
//   //     .pipe(
//   //       tap(_ => this.log('fetched users')),
//   //       catchError(this.handleError<User[]>('getUsers', []))
//   //     );
//   // }
//   //
//   // /** GET user by id. Will 404 if id not found */
//   // getUser(id: number): Observable<User> {
//   //   const url = `${this.usersUrl}/${id}`;
//   //   return this.http.get<User>(url).pipe(
//   //     tap(_ => this.log(`fetched user id=${id}`)),
//   //     tap(data => console.log(data)),
//   //     catchError(this.handleError<User>(`getUser id=${id}`))
//   //   );
//   // }
//   //
//   //
//   //
//   //
//   //
//   // /** POST: add a new user to the server */
//   // addUser(user: User): Observable<ApiResult> {
//   //   console.log('addUser' + user.mobile);
//   //   console.log('addUser' + user.name);
//   //   console.log('addUser' + user.email);
//   //   console.log('addUser' + user.remarks);
//   //   console.log('addUser' + user.status);
//   //   const data = new URLSearchParams();
//   //   const bodyData = {
//   //     name: 'nullfy6HS'
//   //   };
//   //   const httpParams = {name: 'nullfy6HS'};
//   //   data.append('body', JSON.stringify(bodyData));
//   //   // const params = {'name': user.name, 'mobile': user.mobile, 'email': user.email,
//   //   //   'remarks': user.remarks, 'status': user.status};
//   //   // {name: 'fdfa', mobile: 'dafds', email: 'dfdfdfd',
//   //   //   remarks: 'sfdfadfsdf', status: '2'}
//   //   return this.http.post<ApiResult>(this.usersUrl, user , httpOptions).pipe(
//   //     // tslint:disable-next-line:no-shadowed-variable
//   //     tap(data => console.log(data)),
//   //     catchError(this.handleError<ApiResult>('addUser'))
//   //   );
//   // }
//   //
//   // /** PUT: update the user on the server */
//   // updateUser(user: User): Observable<ApiResult> {
//   //   // @ts-ignore
//   //   return this.http.put(this.usersUrl, user, httpOptions).pipe(
//   //     tap(_ => this.log(`updated user id=${user.id}`)),
//   //     catchError(this.handleError<ApiResult>('updateUser'))
//   //   );
//   // }
//   //
//   //
//   // /** DELETE: delete the user from the server */
//   // deleteUser(user: User | number): Observable<ApiResult> {
//   //   const id = typeof user === 'number' ? user : user.id;
//   //   const url = `${this.usersUrl}/${id}`;
//   //
//   //   return this.http.delete<ApiResult>(url, httpOptions).pipe(
//   //     tap(_ => this.log(`deleted user id=${id}`)),
//   //     catchError(this.handleError<ApiResult>('deleteUser'))
//   //   );
//   // }
//   //
//

// }

