import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {RegisterComponent } from './register.component';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {SharedModule} from '../../shared/shared.module';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {RegisterRoutingModule} from './register-routing.module';


@NgModule({
  declarations: [
    RegisterComponent
  ],
    imports: [
        CommonModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzCheckboxModule,
        FormsModule,
        NzLayoutModule,
        NzTabsModule,
        NzAlertModule,
        ReactiveFormsModule,
        RegisterRoutingModule,
        SharedModule,
    ],
  exports: [
    RegisterComponent
  ]
})
export class RegisterModule { }
