import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ucenter',
  templateUrl: './ucenter.component.html',
  styleUrls: ['./ucenter.component.css']
})
export class UcenterComponent implements OnInit {

  isCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

}
