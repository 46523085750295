import { Component, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import {Constants} from '../../../../@core/constant/Constants';

@Component({
  selector: 'app-chick-chat-drawer',
  templateUrl: './chick-chat-drawer.component.html',
  styleUrls: ['./chick-chat-drawer.component.css']
})
export class ChickChatDrawerComponent implements OnInit {

  currentUser = 'me';
  // 评论列表
  // comments = Constants.comments;
  // // 评论区@的成员列表
  // memberList = Constants.memberList;
  selectedMembers = [];
  commentFragment = [];
  showComment = false;
  showMember = false;
  onInput = false;
  comment = '';
  messages: Message[] = [
    {  sender: '小鸡',
      content: '比比比比xxxx比比比比xxxx',
    },
    {  sender: '小鸡',
      content: '比比比比xxxx比比比比xxxx',
    },
    {  sender: 'me',
      content: '比比比比xxxx比比比比xxxx',
    }
  ];
  newMessage: string;

  ngOnInit(): void {
  }

  constructor(private drawerRef: NzDrawerRef<string>) {}

  close(): void {
    this.drawerRef.close();
  }



  // sendMessage() {
  //   if (this.newMessage) {
  //     const message: Message = {
  //       sender: 'me',
  //       content: this.newMessage,
  //     };
  //     this.messages.push(message);
  //     this.newMessage = '';
  //   }
  // }

  focus(textarea?: HTMLTextAreaElement): void {
    this.onInput = true;
    if (textarea) {
      textarea.focus();
    }
  }
  blur($event): void {
    this.onInput = false;
    if ($event.target.id !== 'input') {
    }
  }
  commentChange($event): void {
    if ($event.search(/@$/g) > -1) {
      this.showMember = true;
      this.commentFragment = this.commentFragment.concat({ label: '@', value: null });
    } else {
      const lastString = this.comment.slice(-1);
      this.commentFragment = this.commentFragment.concat([{ label: lastString, value: null }]);
      this.showMember = false;
    }
  }
  selectMember(item): void {
    this.comment = this.comment + item.label;
    this.commentFragment.pop();
    this.commentFragment = this.commentFragment.concat([{ label: '@' + item.label, value: item.value }]);
  }
}



interface Message {
  sender: string;
  content: string;
}
