import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {UcenterComponent} from './pages/ucenter/ucenter.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {RedirectComponent} from './pages/redirect/redirect.component';
import {TermsAndConsComponent} from './pages/terms-and-cons/terms-and-cons.component';
import {ProfileComponent} from './pages/settings/profile/profile.component';
import {LogoutComponent} from './@core/listener/logout/logout.component';
import {RefreshTokenComponent} from './@core/listener/refresh-token/refresh-token.component';

// @ts-ignore
const routes: Routes = [
  { path: 'mainlogoutrecevier', component: LogoutComponent},
  { path: 'mainloginrecevier', component: RefreshTokenComponent},
  { path: '', pathMatch: 'full', redirectTo: '/ucenter' },
  // { path: 'login',  component: LoginComponent},
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  // { path: 'register',  component: RegisterComponent},
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
  },
  { path: 'ucenter', component: UcenterComponent },
  { path: 'dashborad', component: RedirectComponent , pathMatch: 'full'},
  { path: 'termsandcons', component: TermsAndConsComponent , pathMatch: 'full'},
  // { path: 'profile', component: ProfileComponent },
  // { path: 'company', component: CompanyComponent ,
  //   children: [
  //     { path: 'information', component: InformationComponent },
  //     { path: 'version', component: VersionComponent },
  //     { path: '**', component: InformationComponent }
  //   ]
  // },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
  },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
