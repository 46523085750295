import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UcenterComponent } from './ucenter.component';
import {DemoNgZorroAntdModule} from '../../ng-zorro-antd.module';
// import {LeftMenuComponent} from './left-menu/left-menu.component';
import {MainPartComponent} from './main-part/main-part.component';
// import {TopMenuComponent} from './top-menu/top-menu.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {NzImageModule} from 'ng-zorro-antd/experimental/image';


@NgModule({
  declarations: [
    UcenterComponent,
    // LeftMenuComponent,
    MainPartComponent,
    // TopMenuComponent
  ],
    imports: [
        CommonModule,
        DemoNgZorroAntdModule,
        DragDropModule,
        RouterModule,
        SharedModule,
        NzImageModule
    ],
  exports: [
    UcenterComponent
  ]
})
export class UcenterModule {
  private static ScrollingModule: any;
}
