<div class="header-bar">
  <div class="wrap">
      <div class="left">
        <app-logo></app-logo>
      </div>
      <div class="right">
          <div [hidden]="!isShowRegMenu" >
              <a [routerLink]="['../login']">
                <button nz-button nzType="primary" nzSize="large"  class="button-class">
                  登陆
                </button>
              </a>
              <a [routerLink]="['../register']">
                <button nz-button nzType="primary" nzSize="large"  class="button-class">
                  注册
                </button>
              </a>
          </div>
          <div [hidden]="!isShowUserMenu" class="user-part">
                <div class="workbench" (click)="jumpToWorkbench()">
                  <i nz-icon nzType="dashboard" nzTheme="twotone" nzTwotoneColor="#4ba3ef"></i><span style="font-weight: bold;">工作台</span>
                </div>
                <div class="divider" style="margin-left: 0px;margin-right: 0px;"></div>
                <nz-avatar [nzSize]="30" nzIcon="user"  class="user-icon"  class="user-icon"
                             [nzSrc]="getUserHeaderImg()"
                           nz-dropdown [nzDropdownMenu]="userMenu" nzPlacement="bottomRight"></nz-avatar>
                <nz-dropdown-menu #userMenu="nzDropdownMenu" class="user-setting-menu">
                  <ul nz-menu class="user-setting-menu">
                    <li nz-menu-item class="first_item">
                      <div class="user-setting-menu-item-user"><span><span nz-icon nzType="user" nzTheme="outline"></span>  {{userName}}</span></div>
                      <div class="user-setting-menu-item-company"><span><span nz-icon nzType="group" nzTheme="outline"></span> {{companyName}}</span></div>
                    </li>
                    <li nz-menu-divider></li>
                    <li nz-menu-item [routerLink]="['../settings/profile']" ><span nz-icon nzType="setting" nzTheme="outline"></span><span class="user-setting-menu-item" >人个设置</span></li>
                    <li nz-menu-item [routerLink]="['../settings/company']" ><span nz-icon nzType="setting" nzTheme="outline"></span><span class="user-setting-menu-item" >团队/企业设置</span></li>
                    <li nz-menu-divider></li>
                    <li nz-menu-item (click)="logout()"><span nz-icon nzType="logout" nzTheme="outline"></span><span class="user-setting-menu-quit">退出</span></li>
                  </ul>
                </nz-dropdown-menu>

      </div>
  </div>
</div>
<div class="header-base-line">
</div>


