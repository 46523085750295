<!--<nz-content style="background-color: yellow">-->
<!--    <nz-carousel nzAutoPlay class="carousel-content">-->
<!--      <div nz-carousel-content *ngFor="let index of array"-->
<!--           class="carousel-content">-->
<!--        <img-->
<!--          nz-image-->
<!--          class="tab-img"-->
<!--          nzSrc="{{index[1]}}"-->
<!--          alt=""/>-->
<!--        <div class="overlay">-->
<!--          <div class="text2">  预告  </div>-->
<!--          <div class="text"> {{index[0]}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </nz-carousel>-->
<!--  <div class="platform-main">-->
<!--    <span class="platform-title">Low Code + RPA + AI </span>-->
<!--  </div>-->
<!--  <audio src="assets/music/stareater.mp3" autoplay="autoplay" preload="auto" controls="controls" loop="loop"></audio>-->
<!--</nz-content>-->

<!--<div class="section" *ngFor="let section of sections">-->
<!--  <img [src]="section.imageUrl" alt="Section Image">-->
<!--</div>-->

<nz-carousel #carousel nzAutoPlay class="carousel-content" nzAutoPlaySpeed="5000"  >
  <div  nz-carousel-content *ngFor="let item of array; let i=index;"
       class="carousel-content">

    <ng-container *ngIf="i !== 3 ; else elsePage">
      <img
        nz-image
        [class]="'tab-img'"
        nzSrc="{{item[1]}}"
        alt=""/>
    </ng-container>
    <ng-template #elsePage>
      <div>
        <a href="https://nocode.umuchicken.com/pages/market/form" >
        <img
          nz-image
          [class]="'tab-img2'"
          nzSrc="{{item[1]}}"
          alt=""/></a>
<!--        <iframe style="width: 70%; height:90%;margin-top: 100px;border: none;" src="http://localhost:81/pages/market/form"></iframe>-->
      </div>
    </ng-template>
  </div>
</nz-carousel>
<button  class="carousel-nav carousel-prev" (click)="prevSlide()">
  <span class="arrow-left"></span>
</button>
<button   class="carousel-nav carousel-next" (click)="nextSlide()">
  <span class="arrow-right"></span>
</button>
