import { Component, OnInit } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {Constants} from '../../../@core/constant/Constants';
import {TenantService} from '../../../@core/services/http/tenant/tenant.service';
import {LocalStorageService} from '../../../@core/services/data/local-storage.service';
import {AuthService} from '../../../@core/services/http/auth/auth.service';
import {JumpService} from '../../../@core/services/http/jump/jump.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-header-bar2',
  templateUrl: './header-bar2.component.html',
  styleUrls: ['./header-bar2.component.css']
})
export class HeaderBar2Component implements OnInit {

  isShowRegMenu = false;
  isShowUserMenu = false;
  userName: string;
  companyName = '公司A';

  baseImageUrl = environment.baseImageUrl;
  baseImageCommonUrl = environment.baseImageCommonUrl;
  userDefaultLogo = this.baseImageCommonUrl + Constants.DEFAULT_USER_HEAER_IMG;
  userHeaderImg: any;
  imageUrl: any;

  constructor(
    public localStorageService: LocalStorageService,
    public tenantService: TenantService,
    private jumpService: JumpService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.isLogin();
  }

  isLogin(): void {

    const tokenStr  = this.localStorageService.getToken();
    if (tokenStr === null || tokenStr === '' || typeof  tokenStr  === 'undefined') {
      this.isShowUserMenu = false;
      this.isShowRegMenu = true;
      return;
    }
    this.authService.getUserProfileByToken(tokenStr)
      .subscribe(res => {
            if (res.success) {
                this.isShowUserMenu = true;
                this.isShowRegMenu = false;
                this.userName = res.result.name;
                this.userHeaderImg = res.result.imgUrl;
                if (this.userName == null || this.userName === '' ) {
                  this.userName = '用户名(未设置)';
                }
                this.loadTenantProfile();
            } else  {
                this.isShowUserMenu = false;
                this.isShowRegMenu = true;
            }
    });
  }

  logout(): void {
    this.localStorageService.clearUserInfo();
    this.localStorageService.logoutNotificationForContact();
    this.localStorageService.logoutNotificationForWorkbrench();
    this.ngOnInit();
  }

  jumpToWorkbench(): void {
     this.jumpService.jumpToWork();
  }

  public loadTenantProfile(): void {

    this.tenantService.getTenantProfile()
      .subscribe(res => {
        if (res.success) {
          this.localStorageService.setTenantUnionId(res.result.unionId);
          this.companyName = res.result.name;
        }
      });
  }

  getUserHeaderImg(): string {

    if ( this.userHeaderImg === null || typeof this.userHeaderImg === 'undefined')
    {
       return this.userDefaultLogo;
    }
    else
    {
      const imageUrl = this.baseImageUrl + 'user/' + this.userHeaderImg;
      return imageUrl;
    }

  }
}
