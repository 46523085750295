import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../services/data/local-storage.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    console.log('[logout]main ngOnInit addEventListener before');
    window.addEventListener('message', (event) => {
      console.log('[logout]main addEventListener receive data========');
      if (event.data.type !== 'webpackOk' && event.data.type !== 'webpackInvalid')
      {
        console.log('[logout]clear token');
        this.localStorageService.clearUserInfo();
      }

    });
    console.log('[logout]main ngOnInit addEventListener after');
  }

}
