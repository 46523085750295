import { Injectable } from '@angular/core';
import { BaseHttpService } from '../base-http.service';
import {Observable} from 'rxjs';
import {BaseService} from '../../base.service';

@Injectable({
  providedIn: 'root'
})
export class MarketService extends BaseService {

  constructor(private http: BaseHttpService) {
    super();
  }

  installApp(id: string): Observable<any> {
    return this.http.post('define/market/app/api/install?id=' + id + '&type=1', '', null, true);
  }

  installAllDefaultApp(): Observable<any> {
    return this.http.post('define/market/app/api/install/default', '', null, true);
  }

  installDefaultApp(): void {
    // this.installApp('1786849294016315392')
    //   .subscribe(res => {
    //   });
    this.installAllDefaultApp()
      .subscribe(res => {
      });
  }

}
