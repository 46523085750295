import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-cons',
  templateUrl: './terms-and-cons.component.html',
  styleUrls: ['./terms-and-cons.component.css']
})
export class TermsAndConsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
