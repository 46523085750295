import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  baseApiUrl = environment.baseApiUrl;
  baseMainWebUrl = environment.baseMainWebUrl;
  baseWorkWebUrl = environment.baseWorkWebUrl;
  baseContactWebUrl = environment.baseContactWebUrl;

  constructor() { }
}
