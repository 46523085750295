import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './components/logo/logo.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { FooterBarComponent } from './components/footer-bar/footer-bar.component';
import { HeaderBar2Component } from './components/header-bar2/header-bar2.component';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {RouterModule} from '@angular/router';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {ChickChatDrawerComponent} from './components/chick-chat/chick-chat-drawer/chick-chat-drawer.component';


@NgModule({
  declarations: [
    LogoComponent,
    HeaderBarComponent,
    FooterBarComponent,
    HeaderBar2Component,
    ChickChatDrawerComponent
  ],
  exports: [
    LogoComponent,
    HeaderBarComponent,
    FooterBarComponent,
    HeaderBar2Component,
    ChickChatDrawerComponent
  ],
    imports: [
        CommonModule,
        NzLayoutModule,
        RouterModule,
        NzButtonModule,
        NzTagModule,
        NzAvatarModule,
        NzDropDownModule,
        NzIconModule
    ]
})
export class SharedModule { }
