import { Injectable } from '@angular/core';
import {Constants} from '../../../constant/Constants';
import {BaseService} from '../../base.service';
import {LocalStorageService} from '../../data/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class JumpService extends BaseService {

  constructor(public localStorageService: LocalStorageService) {
    super();
  }


  jumpToWork(): void  {
    window.location.href = this.baseWorkWebUrl + Constants.URL_MOD_FORM;
  }

  jumpToWork100(): void  {
    setTimeout(() => {
      window.location.href = this.baseWorkWebUrl + Constants.URL_MOD_FORM;
    }, 100);
  }

  // jumpToWork2000(): void  {
  //   setTimeout(() => {
  //     window.location.href = this.baseWorkWebUrl + Constants.URL_MOD_FORM;
  //   }, 4000);
  // }

  jumpToWork2000WithToken(): void  {
    setTimeout(() => {
      window.location.href = this.baseWorkWebUrl + Constants.URL_MOD_FORM + '?id=' + this.localStorageService.getToken() ;
    }, 4000);
  }

  jumpToLogin(): void  {
    setTimeout(() => {
      window.location.href = this.baseMainWebUrl + Constants.URL_MOD_LOGIN;
    }, 100);
  }

  jumpToLoginByTime(timePara: any): void  {
    setTimeout(() => {
      window.location.href = this.baseMainWebUrl + Constants.URL_MOD_LOGIN;
    }, timePara);
  }

  jumpToMain(): void  {
    setTimeout(() => {
      window.location.href = this.baseMainWebUrl;
    }, 100);
  }

}
