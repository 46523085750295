import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../../@core/services/data/local-storage.service';
import {AuthService} from '../../../@core/services/http/auth/auth.service';


@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css']
})
export class HeaderBarComponent implements OnInit {

  constructor(
    public localStorageService: LocalStorageService,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

}
