import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../services/data/local-storage.service';

@Component({
  selector: 'app-refresh-token',
  templateUrl: './refresh-token.component.html',
  styleUrls: ['./refresh-token.component.css']
})
export class RefreshTokenComponent implements OnInit {

  constructor(public localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    console.log('[login]main ngOnInit addEventListener before');
    window.addEventListener('message', (event) => {
      console.log('[login]main customd addEventListener receive data========');
      if (event.data.type !== 'webpackOk' && event.data.type !== 'webpackInvalid') {
        console.log('[login]main save token');
        this.localStorageService.setToken(event.data);
      }
    });
    console.log('[login]main ngOnInit addEventListener after');
  }
}
