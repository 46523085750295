import { Component, OnInit } from '@angular/core';
import {Constants} from '../../@core/constant/Constants';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href = Constants.URL_MOD_FORM;
  }

}
