import { Component, OnInit } from '@angular/core';
import {Constants} from '../../../@core/constant/Constants';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  baseImageCommonUrl = environment.baseImageCommonUrl;
  userLogo = this.baseImageCommonUrl + Constants.USER_LOGO_IMG;
  logoTitle = this.baseImageCommonUrl + Constants.LOGO_TITLE;
  constructor() { }

  ngOnInit(): void {
  }


}
